import { Container, Col, Row } from "react-bootstrap";
import styled from "styled-components";
import appImage from "../Assets/img/app_mockup_landing3.png";
import LogoImg from '../Assets/img/app_icon_min.png';
import GoogleBadge from "../Assets/img/google_badge.png";
import AppleBadge from "../Assets/img/apple_badge.png";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <PageBackground className="align-items-center">
      <Col>
        <Container className="p-5">
          <Row className="align-items-center g-0 mb-5">
            <Col className="text-center p-5" xs="12" lg="6">
              <AppLanding src={appImage} />
            </Col>
            <Col className="p-5" xs="12" lg="6">
              <Row className='align-items-center mb-5'>
                <Col xs="auto">
                  <Logo src={LogoImg} />
                </Col>
                <Col xs="auto">
                  <AppTitle>Bugeto: Financial Planner</AppTitle>
                </Col>
              </Row>
              <AppH1>Control your expenses through a technique of separating the monthly budget.</AppH1>
              <AppP className="mb-2">
              The Bugeto App keeps track of your monthly expenses, helping you to prosper financially through a practical methodology for allocating your monthly budget. The 10/55/10/10/15 methodology.
              </AppP>
              <AppP className="mb-5">
              With the Bugeto App you can view your earnings and expenses month by month and better understand the proportion between them. That way you'll always know if you're spending much more than you're earning or if you're in control of spending.
              </AppP>
              <Row className='align-items-center mb-5 g-0'>
                <Col xs="auto" className="p-0">
                  <a href="https://apps.apple.com/us/app/bugeto-financial-planner/id6443726432"><BadgeImg src={AppleBadge} alt="Download on the App Store"/></a>
                </Col>
                <Col xs="auto" className="p-0">
                  <a href="https://play.google.com/store/apps/details?id=com.crjlab.bugeto"><BadgeImg src={GoogleBadge} alt="Get it on Google Play" /></a>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className='justify-content-between p-5'>
            <MadeCol xs="12" md="auto" className="mb-3 text-center">Made with ❤️ in 🇧🇷 by Cloves R. Junior</MadeCol>
            <Col xs="12" md="auto" className="mb-3 text-center">
              <LinkText to="/privacy">Privacy Policy</LinkText>
            </Col>
            <Col xs="12" md="auto" className="mb-3 text-center">
              <LinkText to="/contact">Contact Us</LinkText>
            </Col>
          </Row>
        </Container>
      </Col>
    </PageBackground>
  );
}

const LinkText = styled(Link)`
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: white;
`;

const MadeCol = styled(Col)`
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: white;
`;

const BadgeImg = styled.img`
  height: 50px;
  margin-right: 8px;
  margin-top: 16px;
`;

const PageBackground = styled(Row)`
  background-color: #5795dc;
  min-height: 100vh;
`;

const AppLanding = styled.img`
  width: 100%;
`;

const Logo = styled.img`
  width: 60px;
  height: 60px;
  padding: 0px;
  border-radius: 10px;
`;

const AppTitle = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: white;
`;

const AppH1 = styled.h1`
  color: white;
`;

const AppP = styled.p`
  color: white;
`;

export default Home;