import {
  Routes,
  Route,
} from "react-router-dom";
import Home from "./Pages/Home";
import Contact from "./Pages/Contact";
import Privacy from "./Pages/Privacy";

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="*" element={<Home />} />
    </Routes>
  );
}

export default AppRouter;